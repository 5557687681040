import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { doSignInWithEmailAndPassword, doSignInWithGoogle, doSignInWithMicrosoft } from '../auth/firebase/auth'; // Import the function for Microsoft sign-in
import { useAuth } from '../auth/authContext';   
import dynapt from "../../assests/dynapt1.png";        

const LoginPage = () => {
    const { userLoggedIn } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isSigningIn, setIsSigningIn] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();
    
    const handleCheckboxChange = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            await doSignInWithEmailAndPassword(email, password).catch(err => {
                setErrorMessage("INVALID CREDENTIALS");
                setIsSigningIn(false);
            })
        }
    }

    const onGoogleSignIn = (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            doSignInWithGoogle().catch(err => {
                setIsSigningIn(false)
            })
        }
    }
    
    const onMicrosoftSignIn = (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            doSignInWithMicrosoft().catch(err => {
                setIsSigningIn(false)
            })
        }
    }

    // useEffect(() => {
    //     const handlePopupClose = () => {
    //         if (isSigningIn) {
    //             setIsSigningIn(false);
    //         }
    //     };
    
    //     window.addEventListener('focus', handlePopupClose);
    //     return () => {
    //         window.removeEventListener('focus', handlePopupClose);
    //     };
    // }, [isSigningIn]);

    return (
        <div>
            {userLoggedIn && (<Navigate to={'/'} replace={true} />)}

            <main className="w-full flex self-center place-content-center place-items-center">
                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <img src={dynapt} width="160" alt="dynapt" className="img-fluid" />
                    </div>
                    <div className="text-center">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Login</h3>
                        </div>
                    </div>
                    <form
                        onSubmit={onSubmit}
                        className="space-y-5"
                    >
                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                                Email
                            </label>
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                value={email} onChange={(e) => { setEmail(e.target.value); setErrorMessage(''); }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div className="relative">
                            <label className="text-sm text-gray-600 font-bold">
                                Password
                            </label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setErrorMessage('');
                                }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                            <div className="flex items-center mt-2">
                                <input
                                    type="checkbox"
                                    checked={showPassword}
                                    onChange={handleCheckboxChange}
                                    className="w-4 h-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label className="ml-2 mt-2 text-sm text-gray-600" onClick={handleCheckboxChange}>
                                    Show Password
                                </label>
                            </div>
                        </div>

                        {errorMessage && (
                            <span className='text-red-600 font-bold'>{errorMessage}</span>
                        )}

                        <button
                            type="submit"
                            disabled={isSigningIn}
                            className={`w-full px-4 py-2 text-white font-medium rounded-lg ${isSigningIn ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'}`}
                        >
                            {isSigningIn ? 'Signing In...' : 'Sign In'}
                        </button>
                    </form>
                    <div className="text-sm text-center">
                        Already have an account?{' '}
                        <button
                            onClick={() => navigate('/signup')}
                            className="text-sm underline font-bold hover:text-blue-500 focus:outline-none"
                        >
                            Sign up
                        </button>
                    </div>
                    <div className='flex flex-row text-center w-full'>
                        <div className='border-b-2 mb-2.5 mr-2 w-full'></div><div className='text-sm font-bold w-fit'>OR</div><div className='border-b-2 mb-2.5 ml-2 w-full'></div>
                    </div>
                    <button
                        disabled={isSigningIn}
                        onClick={(e) => { onGoogleSignIn(e) }}
                        className={`w-full flex items-center justify-center gap-x-3 py-2.5 border rounded-lg text-sm font-medium  ${isSigningIn ? 'cursor-not-allowed' : 'hover:bg-gray-100 transition duration-300 active:bg-gray-100'}`}>
                        <svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <g clipPath="url(#clip0_17_40)">
                                <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
                                <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
                                <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
                                <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
                             </g>
                             <defs>
                                 <clipPath id="clip0_17_40">
                                    <rect width="48" height="48" fill="white" />
                                 </clipPath>
                             </defs>
                        </svg>
                        {isSigningIn ? 'Signing In...' : 'Continue with Google'}
                    </button>
                    
                    <button
                        disabled={isSigningIn}
                        onClick={(e) => { onMicrosoftSignIn(e) }}
                        className={`w-full flex items-center justify-center gap-x-3 py-2.5 border rounded-lg text-sm font-medium ${isSigningIn ? 'cursor-not-allowed' : 'hover:bg-gray-100 transition duration-300 active:bg-gray-100'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
                          <path fill="#f1511b" d="M121.666 121.666H0V0h121.666z" />
                          <path fill="#80cc28" d="M256 121.666H134.335V0H256z" />
                          <path fill="#00adef" d="M121.663 256.002H0V134.336h121.663z" />
                          <path fill="#fbbc09" d="M256 256.002H134.335V134.336H256z" />
                        </svg>
                        {isSigningIn ? 'Signing In...' : 'Continue with Microsoft'}
                    </button>    
                </div>
            </main>
        </div>
    )
};

export default LoginPage;

