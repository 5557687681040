import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import { doCreateUserWithEmailAndPassword, doSendEmailVerification } from '../auth/firebase/auth';
import { doSignOut } from '../auth/firebase/auth';
import CryptoJS from 'crypto-js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import dynapt from "../../assests/dynapt1.png";
import { setOption } from 'showdown';

const SignupPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCode, setPhoneCode] = useState('+91');
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [sendingOtp, setSendingOtp] = useState(false);
    const [otpValue, setOtpValue] = useState('');
    const [otpSentToUser, setOtpSentToUser] = useState('0000');
    const [otpSendSuccessMessage, setOtpSendSuccessMessage] = useState('');

    const { userLoggedIn, isEmailVerified } = useAuth();
    let navigate = useNavigate();

    const handleCheckboxChange = () => {
        setShowPassword(!showPassword);
    };

    const removePlusSymbol = (code) => {
        if (code.startsWith('+')) {
            return code.substring(1);
        }
        return code;
    };


    function encryptPassword(password, encryptionKey) {
        const encryptedPassword = CryptoJS.AES.encrypt(password, encryptionKey).toString();
        return encryptedPassword;
    }

    function decryptPassword(encryptedPassword, encryptionKey) {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, encryptionKey);
        const decryptedPassword = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedPassword;
    }

    const validatePassword = () => {
        setPasswordMatch(password === confirmPassword);
    };

    const sendOtp = (resend) => {
        const phCode = removePlusSymbol(phoneCode);
        if(phCode==''){
            setPhoneErrorMessage('Enter Phone Code');
            return;
        }
        if(phoneNumber==''){
            setPhoneErrorMessage('Enter Phone Number');
            return;
        }
        const phone = parsePhoneNumberFromString('+'+phCode+phoneNumber);
        //console.log(phone);
        if(!phone.isValid()){
            setPhoneErrorMessage('Enter a valid Phone Number');
            return;
        }

        setSendingOtp(true);
        const random = Math.floor(Math.random() * 9000 + 1000);
        setOtpSentToUser(random);
        const reqToSend = {
            "receiver": `${phCode}${phoneNumber}@s.whatsapp.net`,
            "message": { "text": `Your otp is ${random}` }
        };

        fetch('https://wa.dynaptsolutions.com:8000/chats/send?' + new URLSearchParams({
            id: 'device_14',
        }), {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqToSend),
        }).then(response => {
            if (!response.ok) {
                throw new Error('Server response was not OK');
            }
            return response;
        })
        .then(() => {
            setOtpSent(true);
            setSendingOtp(false);
            if(!resend){setOtpSendSuccessMessage('OTP resent successfully.')}
            else{setOtpSendSuccessMessage('OTP sent successfully.');}
        }).catch((error) => {
            setSendingOtp(false);
            setPhoneErrorMessage('Failed to send OTP. Please retry.');
            console.log(error);
        })     
    };     
    
    const resendOtp = () =>{
        setPhoneErrorMessage('');
        setOtpValue('');
        sendOtp(false);
    }
                                 
    const verifyOtp = () => {
        setOtpSendSuccessMessage('');
        if(otpValue==''){
            setPhoneErrorMessage("Enter OTP");
            return;
        }
        if(otpValue==otpSentToUser){
        setPhoneNumberVerified(true);
        }
        else{
            setPhoneErrorMessage("Incorrect OTP");
        }
    };
  
    const sendUserInfo = async () =>{
        const phCode = removePlusSymbol(phoneCode);
        const words = name.split(' ');
        let firstName = words[0];
        let lastName = words.length > 1 ? words.slice(1).join(' ') : 'lastName';
        try {
            const response = await fetch('https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/create_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({ 
                    CreatedBy: 1,
                    FirstName: firstName,
                    LastName: lastName,
                    Email: email,
                    MobilePhone: phoneNumber,
                    EncryptedPassword: encryptPassword(password,process.env.REACT_APP_Encryption_Key),
                    PhoneCode: phCode,
                    Organization: "Dynapt"
                }),
            });

        } catch (error) {
            console.log(error);
        }
    };
 
    const onSubmit = async (e) => {
        e.preventDefault();
        if(!phoneNumberVerified){
            setErrorMessage('Please verify your Phone Number');
            return;
        }
        if(password!==confirmPassword) setErrorMessage('Passwords do not match');
        if (!isRegistering && (password===confirmPassword)) {
            setIsRegistering(true);
            await doCreateUserWithEmailAndPassword(email, password)
            .then(()=>{
                doSendEmailVerification();
                sendUserInfo();
            })
            .catch(error =>{
                if(error.message==='Firebase: Error (auth/invalid-email).'){
                    setErrorMessage("Enter a valid Email");
                }else if(error.message ==='Firebase: Password should be at least 6 characters (auth/weak-password).'){
                    setErrorMessage("Enter a Strong Password");
                }else{
                    setErrorMessage("Email Already in Use");
                } 
              setIsRegistering(false);
            });
        }
    };

    return (
        <>
            {!isEmailVerified && userLoggedIn && (
                <div className="p-2 bg-blue-100 text-blue-800 text-center rounded-md">
                    <p>Please check your email and click the verification link to continue.</p>
                    <button className="ml-auto underline hover:text-blue-600" onClick={() => { doSignOut().then(() => { navigate('/') }) }}>Use another email</button>
                </div>
            )}


            {isEmailVerified && userLoggedIn && <Navigate to="/" replace={true} />}

            {!userLoggedIn && (
            <main className="w-full flex self-center place-content-center place-items-center overflow-auto">
                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <img src={dynapt} width="160" alt="dynapt" className="img-fluid" />
                    </div>
                    <div className="text-center mb-6">
                        <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Create a New Account</h3>
                    </div>
                    <form onSubmit={onSubmit} className="space-y-4">
                        <div>
                            <label className="text-sm text-gray-600 font-bold">Name</label>
                            <input
                                type="text"
                                autoComplete="name"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 font-bold">Email</label>
                            <input
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => {setEmail(e.target.value); setErrorMessage('');}}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 font-bold">Phone Number</label>
                            <div className="flex items-center mt-2">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    maxLength={4}
                                    placeholder="+"
                                    value={phoneCode}
                                    onChange={(e) => {setPhoneCode(e.target.value);setPhoneErrorMessage('');}}
                                    className="w-1/5 px-2 py-2 text-gray-500 bg-transparent outline-none rounded-l-lg focus:border-indigo-600 shadow-sm transition duration-300 border border-r-0"
                                />
                                <input
                                    type="tel"
                                    autoComplete="tel"
                                    required
                                    value={phoneNumber}
                                    onChange={(e) => {setPhoneNumber(e.target.value);setPhoneErrorMessage('');}}
                                    className="w-4/5 px-3 py-2 text-gray-500 bg-transparent outline-none focus:border-indigo-600 shadow-sm rounded-r-lg transition duration-300 border border-l-0 border-l"
                                />
                                {!phoneNumberVerified && !otpSent && (
                                    <button
                                        type="button"
                                        disabled={sendingOtp}
                                        onClick={sendOtp}
                                        className="ml-2 px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-300"
                                    >
                                       {sendingOtp? 'Sending...' : 'Send OTP'} 
                                    </button>
                                )}
                            </div>
                            {otpSent && !phoneNumberVerified && (
                                <div className="mt-2 flex items-center">
                                    <input
                                        type="text"
                                        value={otpValue}
                                        onChange={(e) => {setOtpValue(e.target.value); setPhoneErrorMessage(''); setOtpSendSuccessMessage('');}}
                                        className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                                    />
                                    <button
                                        type="button"
                                        onClick={verifyOtp}
                                        className="ml-2 px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-300"
                                    >
                                        Verify OTP
                                    </button>
                                    <button
                                        type="button"
                                        onClick={resendOtp}
                                        className="ml-2 px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-300"
                                    >
                                        Resend OTP
                                    </button>
                                </div>
                            )}
                            {phoneNumberVerified && (
                                <div className="mt-2 flex items-center">
                                    <span className="text-green-500 mr-2">&#10003;</span>
                                    <span className="text-gray-600">Verified</span>
                                </div>
                            )}
                        </div>
                        {phoneErrorMessage&& <span className="text-red-600 font-bold">{phoneErrorMessage}</span>}
                        {otpSendSuccessMessage && <div className="text-green-500">{otpSendSuccessMessage}</div>}
                        <div>
                            <div>
                                <label className="text-sm text-gray-600 font-bold">Password</label>
                                <input
                                    disabled={isRegistering}
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    required
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setErrorMessage('');
                                    }}
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                                />
                            </div>
                            <div>
                                <label className="text-sm mt-3 text-gray-600 font-bold">Confirm Password</label>
                                <input
                                    disabled={isRegistering}
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="off"
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setErrorMessage('');
                                        validatePassword();
                                    }}
                                    className={`w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border ${
                                        passwordMatch ? 'focus:border-indigo-600 border-indigo-600' : 'border-red-500 border-2'
                                    } shadow-sm rounded-lg transition duration-300`}
                                />
                            </div>
                            <div className="flex items-center mt-2">
                                <input
                                    type="checkbox"
                                    checked={showPassword}
                                    onChange={() => setShowPassword(!showPassword)}
                                    className="w-4 h-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label className="ml-2 mt-2 text-sm text-gray-600" onClick={() => setShowPassword(!showPassword)}>
                                    Show Password
                                </label>
                            </div>
                        </div>
                        {errorMessage && <span className="text-red-600 font-bold">{errorMessage}</span>}
                        <button
                            type="submit"
                            disabled={isRegistering}
                            className={`w-full px-4 py-2 text-white font-medium rounded-lg ${
                                isRegistering ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                            }`}
                        >
                            {isRegistering ? 'Signing Up...' : 'Sign Up'}
                        </button>
                        <div className="text-sm text-center">
                            Already have an account?{' '}
                            <button onClick={() => navigate('/')} className="text-center text-sm underline font-bold hover:text-blue-500 focus:outline-none">
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
            </main>
            )}
        </>
    );
};

export default SignupPage;
