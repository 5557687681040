import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import stopSpeakingICON from "../../assests/mute_image.png";
import LoadingIcon from "../../assests/LoadingIcon.gif";
import createAnimation from "../converter";
import ReactAudioPlayer from "react-audio-player";
import { Html } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import Lottie from "lottie-react";
import listening from '../../assests/icon_animations/listening.json'
import speaking from '../../assests/icon_animations/speaking.json'
import loading from '../../assests/icon_animations/loading-dots.json'
import demodata from './data/blend250_out.json';
import audioFile from './data/modi1.wav';
import { GoogleGenerativeAI } from "@google/generative-ai";
// import { io } from "socket.io-client";
// import { socket } from "../../socket";
import axios from "axios";
import { useAuth } from "../auth/authContext";
const showdown = require('showdown');
const converter = new showdown.Converter();
const _ = require('lodash');
const genAI = new GoogleGenerativeAI('AIzaSyBFOkj3Cla3JGGYS1xDTEF6Uol3Mv-Jugc');
const model = genAI.getGenerativeModel({ model: "gemini-pro" });

// const socket = io.connect("https://20.207.204.27:5000");


const ChatWithMicComponent = ({ userEmail, clips, setClips, morphTargets, morphTargetsName, ChatTextBoxtoggle, setChatTextBoxtoggle, setRef, recognition, speechSynthesis, displayOption, setDisplayOption }) => {

    const [hasHistoryApplied, setHasHistoryApplied] = useState(false);
    const [audioSource, setAudioSource] = useState(null);
    const [messages, setMessages] = useState([]);
    const [Historymessages, setHistorymessages] = useState([]);
    const [isListening, setIsListening] = useState(false);
    const [reqSent, setReqSent] = useState(false);
    const [playing, setPlaying] = useState(false);
    const audioPlayer = useRef();
    const micUsed = useRef(false);
    const messagesEndRef = useRef(null);
    const recognitionAborted = useRef(false);
    const [showOptions, setShowOptions] = useState(false);
    const [textInput, setTextInput] = useState("");

    const [attachedImage, setAttachedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const [serverData, setServerData] = useState("");

    const [streamingTaskId, setStreamingTaskId] = useState(null);
    const [IsStreaming, setIsStreaming] = useState(false);
    const [CurrentClips, setCurrentClips] = useState([]);
    const ticket_Id = useRef(null);

    const { currentUser } = useAuth();

    // console.log(socket.id);

    // socket.on("connect_error", (err) => {
    //     // the reason of the error, for example "xhr poll error"
    //     console.log(err.message);
        
    //     // some additional description, for example the status code of the initial HTTP response
    //     console.log(err.description);
        
    //     // some additional context, for example the XMLHttpRequest object
    //     console.log(err.context);
    // });

    // socket.on('send_response', (message) => {
    //     console.log("response:===", message);
    //     makeSpeech(message);
    // });
    


    const handlePaste = (e) => {
        console.log("inside handlePaste fucntion", e)
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (const item of items) {
            if (item.kind === 'file' && item.type.startsWith('image/')) {
                const blob = item.getAsFile();
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImagePreview(e.target.result); // Sets preview URL
                };
                reader.readAsDataURL(blob);
                setAttachedImage(blob); // Sets image file
                break;
            }
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result); // Update the preview state
                setAttachedImage(file); // Save the file if you need to upload it later
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageClick = (imageSrc) => {
        setModalImage(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);



    //console.log(recognitionAborted.current);
    // console.log(messages);
    //console.log("isListening....",isListening);
    // console.log(ChatTextBoxtoggle);
    // console.log(reqSent);
    // console.log(playing);
    // console.log(hasHistoryApplied);
    // console.log(audioSource);



    useEffect(() => {
        HasHistoryApplied()
    }, []);

    const HasHistoryApplied = async () => {
        if (!hasHistoryApplied) {
            let ticketId = "";
            try {
                const response = await axios.get(`https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/FaveoTicketChecker?Email=${userEmail}`);
                console.log("response", response)
                if (response.data.length > 0) {
                    ticketId = response.data[0].TicketId
                    ticket_Id.current = ticketId
                }
            } catch (err) {
                console.log("err==========", err);
            }
            console.log("ticketId================:", ticketId)
            if (ticketId) {
                await fetch(`https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/get_ticket_history?TicketId=${ticketId}`, {
                    method: 'GET'
                    // headers: {
                    //     'Authorization': `Bearer ${accessToken}`,
                    //     'Content-Type': 'application/json',
                    // },
                    // body: JSON.stringify({ ticketid: ticketId }),
                }).then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                }).then(data => {
                    if (data.length && data.length > 0) {
                        data.map((x) => {
                            const htmlContent = converter.makeHtml(x.message);
                            const newBotMessage = { text: htmlContent, sender: x.role, timestamp: formatTime() };
                            setMessages((pre) => [...pre, newBotMessage]);
                        });
                        setHasHistoryApplied(true);
                    }
                }).catch((exception) => {
                    console.log("Failed to load history");
                })
            }
        }
    }


    // Utility function to format the current time
    const formatTime = () => {
        return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    // useEffect(()=>{
    //     socket.on('send_response', (message) => {
    //             console.log("response:===", message);
    //             makeSpeech(message);
    //         });

    //         return ()=>{
    //             socket.off('send_response');
    //         }   
    // },[])

    function extractNames(fullName) {
        const words = fullName.split(' ');
        const firstName = words[0];
        const lastName = words.length > 1 ? words.slice(1).join(' ') : '-';
        return { firstName, lastName };
    }

    const sendMessage = async (text, fromMic) => {
        if (fromMic) micUsed.current = true;
        else {
            setIsListening(false);
            micUsed.current = false;
        }

        // if (!text.trim()) return;
        if (!text.trim() && !imagePreview) return;
        setReqSent(true);
        const newUserMessage = { text: text, sender: "user", timestamp: formatTime(), image: imagePreview };
        setMessages((pre) => [...pre, newUserMessage]);
        setHistorymessages((pre) => [...pre, newUserMessage])

        // Clear the image state after the message is sent
        setAttachedImage(null);
        setImagePreview(null);

        try {
            var baseURL = process.env.REACT_APP_DISPATCHER_BASE_URL + `/get_reply_from_genAI?message=${text}&first_name=${extractNames(currentUser.displayName).firstName}&last_name=${extractNames(currentUser.displayName).lastName}&email=${userEmail}&model_name=gemini`;
            
            
            const data = {
                // Message: text,
                // Email: userEmail,
                // Origin: "web",
                imageData: imagePreview
            };
            const config = {
                headers: {
                    // 'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            };
            const startTime = performance.now();
            let response;
            if(imagePreview == null ){response = await axios.post(baseURL);}
            else {
                response = await axios.post(baseURL,data,config);
            }
            console.log("response:===", response.data.response);
            console.log("Execution time:", performance.now() - startTime, "milliseconds");
            makeSpeech(response.data);
        } catch (err) {
            console.log(err);
        }


        // const imageData= imagePreview? ({
        //     imageData: imagePreview,
        // }):null;

        // const data = {
        //     message: text,
        //     first_name: extractNames(currentUser.displayName).firstName,
        //     last_name: extractNames(currentUser.displayName).lastName,
        //     email: userEmail,
        //     image: imageData,
        //     model_name: 'Gemini'
        // };
        
        // socket.emit('message_from_user', data);
            
        //return new Promise((resolve, reject) => {
           
            // socket.on('send_response', (message) => {
            //     console.log("response:===", message);
            //     makeSpeech(message);
            //     //resolve(); 
            // });

            // socket.off('send response');
            
           
        //     socket.on('error', (err) => {
        //         reject(err);
        //     });
        // });

        // //makeSpeech("cant login into my teams account");
        //  try {
        //     const startTime = performance.now();
        //     const prompt = "answer should be in minimum 20 words";
        //     const result = await model.generateContent(text + '\n' + prompt);
        //     const response = await result.response;
        //     console.log("response....", response.text());
        //     console.log("Execution time(genai):", performance.now() - startTime, "milliseconds");
        //     // makeSpeech(response.text());
        //     let textdata = {
        //         response: response.text(),
        //         sources: []
        //     }
        //     makeSpeech(textdata);
        // } catch (err) {
        //     console.log(err);
        // }
    };

    async function makeSpeech(textdata) {
        try {
            const startTime = performance.now();
            const formData = new FormData();
            ticket_Id.current = textdata.ticket_id;
            formData.append('text', textdata.response);

            if (displayOption == 'ChatOnly') {

                // const htmlContent = converter.makeHtml(textdata.response);
                // const newBotMessage = { text: htmlContent, sender: "GenAI", source: textdata.sources };
                // setMessages((pre) => [...pre, newBotMessage]);
                // setHistorymessages((pre) => [...pre, { text: textdata.response, sender: "GenAI", source: textdata.sources }])

                streamText(textdata.response, textdata.sources);
                setReqSent(false);
            } else {

                const response = await axios.post(process.env.REACT_APP_AVATAR_BACKEND_URI + `/synthesize?blend=${displayOption === "ChatAndAudio" ? false : true}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                let { blend_data, synthesized_audio } = response.data;
                console.log("Execution time(blandshapes):", performance.now() - startTime, "milliseconds");
                // console.log("res blendData...",blend_data);
                // console.log(synthesized_audio);
                // const response = await axios.post(process.env.REACT_APP_AVATAR_BACKEND_URI +'/talk', {text:text, voice:process.env.REACT_APP_AZURE_AD_VOICE_NAME});
                // let { blendData,filename } = response.data;

                const newClips = [];
                //Below blend_data wll be null only in chat only option case
                if (blend_data !== null) {
                    for (let i = 0; i < Object.keys(morphTargets).length; i++) {
                        newClips.push(createAnimation(blend_data, morphTargets[i], morphTargetsName[i]));
                    }
                }
                synthesized_audio = process.env.REACT_APP_AVATAR_BACKEND_URI + '/' + synthesized_audio;
                //console.log(synthesized_audio);
                // filename = process.env.REACT_APP_AVATAR_BACKEND_URI + filename;
                // console.log(filename);
                // const newClips = [];

                // for (let i = 0; i < Object.keys(morphTargets).length; i++) {
                //     newClips.push(createAnimation(demodata, morphTargets[i], morphTargetsName[i]));
                // }
                // const filename = audioFile;
                setAudioSource(synthesized_audio);
                setCurrentClips(newClips);

                // const htmlContent = converter.makeHtml(textdata.response);
                // const newBotMessage = { text: htmlContent, sender: "GenAI", source: textdata.sources };
                // setMessages((pre) => [...pre, newBotMessage]);
                // setHistorymessages((pre) => [...pre, { text: textdata.response, sender: "GenAI", source: textdata.sources }])
                streamText(textdata.response, textdata.sources);
                setReqSent(false);
            }
        } catch (err) {
            console.error(err);
            // const htmlContent = converter.makeHtml(textdata.response);
            // const newBotMessage = { text: htmlContent, sender: "GenAI", source: textdata.sources };
            // setMessages((pre) => [...pre, newBotMessage]);
            // setHistorymessages((pre) => [...pre, { text: textdata.response, sender: "GenAI", source: textdata.sources }])
            speak(textdata.response)
            setTimeout(() => {
            streamText(textdata.response, textdata.sources);
            }, 1000);
           
            setReqSent(false);
        }
    }

     //only call the fucntion when blend failed to response
     const speak = (text) => {
        if (speechSynthesis.speaking) {
            console.error('speechSynthesis.speaking');
            return;
        }
        if (text !== '') {
            try {
                setPlaying(true)
                // Split the text into smaller chunks
                const chunkLength = 120; // Define the maximum chunk length
                const textChunks = text.match(new RegExp('.{1,' + chunkLength + '}[.!?]|.{1,' + chunkLength + '}', 'g'));
 
                // Get list of all available voices
                var voices = speechSynthesis.getVoices();
 
                // Filter for voices that are English-Indian and female (based on name as proxy)
                let indianFemaleVoices;
 
                const userAgent = navigator.userAgent;
                const isChrome = userAgent.includes('Chrome') && userAgent.includes('Safari') && !userAgent.includes('Edg');
                const isEdge = userAgent.includes('Edg');
 
                if (isChrome) {
                    console.log('This is Chrome');
                    indianFemaleVoices = voices.filter(voice => voice.lang === 'hi-IN');
                } else if (isEdge) {
                    console.log('This is Edge');
                    indianFemaleVoices = voices.filter(voice => voice.lang === 'hi-IN' && voice.name === 'Microsoft Swara Online (Natural) - Hindi (India)');
                } else {
                    console.log('This is not Chrome or Edge; it will take the default voice.');
                }
 
                // Function to enqueue speaking of each chunk
                const enqueueChunk = (index) => {
                    if (index < textChunks.length) {
                        var utterThis = new SpeechSynthesisUtterance(textChunks[index]);
 
                        // Use the first Indian female voice if available
                        if (indianFemaleVoices.length > 0) {
                            utterThis.voice = indianFemaleVoices[0];
                        } else {
                            console.log('No Indian female voice available. Using default voice.');
                        }
 
                        utterThis.onend = () => {
                            enqueueChunk(index + 1);
                        };
                        speechSynthesis.speak(utterThis);
                    } else {
                        setPlaying(false);
                    }
                };
 
                // Start speaking from the first chunk
                enqueueChunk(0);
            } catch (error) {
              console.log("error in fall back speak functionality",error)
            }
        }
    };



    const toggleListen = () => {

        if (reqSent) {
            return;
        }
        // If currently speaking, stop the speech synthesis
        if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
        }

        if (isListening) {
            recognition.abort();
            recognitionAborted.current = true;
            setIsListening(false);
        } else {
            recognitionfunc();
            setIsListening(true);
        }
    };

    const recognitionfunc = () => {
        recognitionAborted.current = false;
        recognition.start();
        recognition.onend = () => {
            if (!recognitionAborted.current) {
                recognition.stop();
                setIsListening(false);
            }
        };
    }

    recognition.onresult = (event) => {
        stopEverything()
        const speechToText = event.results[0][0].transcript;
        sendMessage(speechToText, true); // Automatically submit the converted text
    };


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    // const TextInputComponent = () => {
    //     // const [textInput, setTextInput] = useState("");
    //     const handleFormSubmit = (e) => {
    //         e.preventDefault();
    //         if (textInput == "") return;
    //         sendMessage(textInput, false);
    //         setTextInput("");
    //     }

    //     return (
    //         <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
    //             <input
    //                 className="form-control me-2"
    //                 type="text"
    //                 value={textInput}
    //                 onChange={(e) => setTextInput(e.target.value)}
    //                 disabled={reqSent}
    //                 placeholder={isListening ? "Listening..." : "Type your message..."}
    //                 style={{ border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '20px', padding: '10px', marginRight: '10px', marginLeft: '10px' }}
    //             />
    //             <button
    //                 type="submit"
    //                 className="btn"
    //                 disabled={reqSent}
    //                 style={{
    //                     border: 'none',
    //                     borderRadius: '20px',
    //                     background: 'none',
    //                     cursor: 'pointer',
    //                     paddingLeft: window.innerWidth <= 425 ? '0' : '12px'
    //                 }}
    //             >
    //                 <i className="bi bi-send" style={{ fontSize: '1.5em' }}></i>
    //             </button>

    //         </form>
    //     )
    // }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        stopEverything()
        if (textInput == "" && !imagePreview) return;
        sendMessage(textInput, false);
        setTextInput("");
    }

    const HandleChatTextBoxtoggle = () => {
        if (!reqSent) {
            setChatTextBoxtoggle(!ChatTextBoxtoggle);
        }
    }



    // End of play
    function playerEnded(e) {
        setAudioSource(null);
        setPlaying(false);
        setReqSent(false);
        if (micUsed.current) {
            setIsListening(true);
            recognitionfunc();
        }
    }

    // Player is read
    function playerReady(e) {
        console.log('file read');
        setPlaying(true);
        audioPlayer.current.audioEl.current.play();
        setRef();
        setClips(CurrentClips);
    }

    const handleChatTypechange = (e) => {
        setDisplayOption(e.target.value)
        setShowOptions(!showOptions);
        if (e.target.value === 'AvatarOnly') {
            setChatTextBoxtoggle(false);
        } else if (e.target.value === 'ChatAndAvatar' || e.target.value === 'ChatAndAudio' || e.target.value === 'ChatOnly') {
            setChatTextBoxtoggle(true);
        }
    }

    const toggleOptionsVisibility = () => {
        setShowOptions(!showOptions);
    };

    const handleMore = async (data) => {
        localStorage.setItem("SourceData", JSON.stringify(data))
        window.open("/source", "_blank");
    }

    const stopEverything = () => {
        if (playing) {
            audioPlayer.current.audioEl.current.pause();
            setAudioSource(null);
            setPlaying(false);
            setReqSent(false);
            setClips([]);
        }
        if (isListening) {
            recognition.abort();
            setIsListening(false);
        }
        speechSynthesis.cancel(); // Stop any ongoing speech synthesis

        if (streamingTaskId) { // Check if a streaming task is active
            clearTimeout(streamingTaskId); // Stop the streaming task
            setStreamingTaskId(null); // Reset the task ID
            setIsStreaming(false);
        }
    };


    const streamText = (text, sources) => {

        const converter = new showdown.Converter();
        let message = { text: "", sender: "GenAI", source: sources }; // Initialize message object

        // Stream text letter by letter or word by word
        let idx = 0;
        const updateText = () => {
            setIsStreaming(true)
            if (idx < text.length) {
                message.text += text[idx];
                idx++;

                setMessages((prev) => [...prev.slice(0, -1), message]); // Update the last message

                // setTimeout(updateText, 40); // Adjust delay to control speed of streaming
                const taskId = setTimeout(updateText, displayOption == 'ChatOnly' ? 10 : 40); // Schedule the next update
                setStreamingTaskId(taskId); // Save the task ID
            } else {
                setHistorymessages((prev) => [...prev, message]); // Save to history when streaming ends
                setIsStreaming(false); // Set flag to false after streaming finish
            }
        };

        setMessages((prev) => [...prev, message]); // Add initial message to state
        updateText(); // Start streaming
    };

    const [feedback, setFeedback] = useState({});
    const [hoverIndex, setHoverIndex] = useState(null);

    const handleFeedback = async (message, feedbackType, index) => {
        try {
            //console.log(ticket_Id.current);
            const ticketId = ticket_Id.current;
            
            const response = await axios.post(`https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/bot_studio/update_response_feedback`, null, {
                params: {
                    ticketId,
                    message,
                    feedback: feedbackType,
                },
                headers: {
                    'accept': 'application/json'
                }
            });

            setFeedback((prevFeedback) => ({
                ...prevFeedback,
                [index]: feedbackType,
            }));
        } catch (error) {
            console.error("Error giving feedback:", error);
        }
    };

    return (
        <>
            <Canvas style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
                <Html>
                    <ReactAudioPlayer
                        src={audioSource}
                        ref={audioPlayer}
                        onEnded={playerEnded}
                        onCanPlayThrough={playerReady}
                    />
                </Html>
            </Canvas>

            {ChatTextBoxtoggle ?
                <div className={`col-lg-${ChatTextBoxtoggle && (displayOption == 'ChatAndAudio' || displayOption == 'ChatOnly') ? '12' : '6'} col-md-6 col-sm-12`} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: '0' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: "flex", justifyContent: "end", alignItems: "center", flexDirection: 'column' }}>
                        <div style={{
                            height: window.innerWidth <= 425 ? (displayOption == 'ChatAndAudio' || displayOption == 'ChatOnly') ? '75vh' : '40vh' : '65vh',
                            width: '100%',
                            overflowY: "scroll",
                            scrollbarColor: 'none',
                            scrollbarWidth: 'none',
                            marginBottom: '10px',
                        }}>
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                style={{
                                    marginTop: '10px',
                                    padding: "5px",
                                    textAlign: message.sender === "GenAI" ? "left" : "right",
                                    position: 'relative',
                                }}
                                onMouseEnter={() => setHoverIndex(index)}
                                onMouseLeave={() => setHoverIndex(null)}
                            >
                                <div style={{ textAlign: message.sender === "GenAI" ? "left" : "right", marginBottom: '5px', color: '#888', fontSize: '0.8em' }}>
                                    {message.timestamp}
                                </div>
                                <span
                                    style={{
                                        backgroundColor: message.sender === "GenAI" ? "#11d044" : "#E0E0E0",
                                        color: message.sender === "GenAI" ? "white" : "#000",
                                        padding: "8px",
                                        borderTopLeftRadius: message.sender === "GenAI" ? 0 : "15px",
                                        borderTopRightRadius: message.sender === "GenAI" ? "15px" : 0,
                                        borderBottomRightRadius: "15px",
                                        borderBottomLeftRadius: "15px",
                                        boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                                        wordWrap: "break-word",
                                        maxWidth: "80%",
                                        display: "inline-block",
                                        overflowWrap: "break-word",
                                        textAlign: "left",
                                        position: "relative",
                                    }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: message.text }} />
                                    {message.image && (
                                        <img
                                            src={message.image}
                                            alt="Attached"
                                            style={{ maxWidth: '200px', maxHeight: '200px', display: 'block', marginTop: '10px', cursor: "pointer" }}
                                            onClick={() => handleImageClick(message.image)}
                                        />
                                    )}
                                    {message.sender === "GenAI" && message.source && message.source.length > 0 && (
                                        <div style={{ marginTop: "10px" }}>
                                            {message.source.map((item, index) => (
                                                <a
                                                    key={index}
                                                    href={item}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ color: "blue", cursor: "pointer", display: "block", marginBottom: "10px" }}
                                                >
                                                    {item}
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                    {message.sender === "GenAI" && hoverIndex === index && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '-40px',
                                                right: '10px',
                                                display: 'flex',
                                                gap: '5px',
                                            }}
                                        >
                                            <button
                                                onClick={() => handleFeedback(message.text, 'Like', hoverIndex)}
                                                style={{
                                                    backgroundColor: feedback[hoverIndex] === 'Like' ? '#008CBA' : '#E0E0E0',
                                                    color: feedback[hoverIndex] === 'Like' ? 'white' : 'black',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '20px',
                                                    padding: '5px 10px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                👍
                                            </button>
                                            <button
                                                onClick={() => handleFeedback(message.text, 'Dislike', hoverIndex)}
                                                style={{
                                                    backgroundColor: feedback[hoverIndex] === 'Dislike' ? '#f44336' : '#E0E0E0',
                                                    color: feedback[hoverIndex] === 'Dislike' ? 'white' : 'black',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '20px',
                                                    padding: '5px 10px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                👎
                                            </button>
                                        </div>
                                    )}
                                </span>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                        </div>
                        {(ChatTextBoxtoggle && window.innerWidth <= 425) ?
                            <div className="col d-flex align-items-center justify-content-start" style={{ width: '100%', padding: '0' }}>
                                {displayOption == 'ChatOnly' ? null :
                                    <>
                                        {isListening ?
                                            <span style={{ cursor: 'pointer', color: "#32B749" }} onClick={toggleListen}>
                                                <i className="bi bi-mic-fill" style={{ fontSize: '1.8em' }}></i>
                                            </span>
                                            : <span style={{ cursor: 'pointer', color: "#32B749" }} onClick={toggleListen} >
                                                <i className="bi bi-mic-mute-fill" style={{ fontSize: '1.8em' }}></i>
                                            </span>
                                        }
                                        {/* {playing && (
                                            <div
                                                data-toggle="tooltip"
                                                title="Stop Speaking"
                                                data-placement="top"
                                                onClick={stopEverything}
                                                style={{ position: 'relative', width: '3.8em', height: '3.8em', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <Lottie animationData={speaking} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }} />
                                                <img src={stopSpeakingICON} alt="stop speaking" style={{ position: 'absolute', width: '30%', height: '30%', objectFit: 'contain' }} />
                                            </div>
                                        )} */}
                                    </>
                                }

                                {/* <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <input
                                        className="form-control me-2"
                                        type="text"
                                        value={textInput}
                                        onChange={(e) => setTextInput(e.target.value)}
                                        disabled={reqSent}
                                        placeholder={isListening ? "Listening..." : "Type your message..."}
                                        style={{ border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '20px', padding: '10px', marginRight: '10px', marginLeft: '10px' }}
                                    />
                                    <button
                                        type="submit"
                                        className="btn"
                                        disabled={reqSent}
                                        style={{
                                            border: 'none',
                                            borderRadius: '20px',
                                            background: 'none',
                                            cursor: 'pointer',
                                            paddingLeft: window.innerWidth <= 425 ? '0' : '12px'
                                        }}
                                    >
                                        <i className="bi bi-send" style={{ fontSize: '1.5em' }}></i>
                                    </button>

                                </form> */}
                                <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: '#FFF', // Background of the entire input box
                                        borderRadius: '20px',
                                        boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                                        padding: '5px 5px',
                                        width: '100%', // Ensure the container takes full width of the form
                                        marginRight: '10px',
                                        marginLeft: '10px'
                                    }}>
                                        <input
                                            className="form-control me-2"
                                            type="text"
                                            value={textInput}
                                            onChange={(e) => setTextInput(e.target.value)}
                                            onPaste={handlePaste}
                                            disabled={reqSent}
                                            placeholder={isListening ? "Listening..." : "Type your message..."}
                                            style={{
                                                border: 'none',
                                                flexGrow: 1, // Allows the text input to fill the space
                                                borderRadius: '20px',
                                                padding: '8px 12px',
                                                outline: 'none',
                                                boxShadow: 'none'
                                            }}
                                        />
                                        {imagePreview && (
                                            <div style={{
                                                position: 'relative',
                                                width: '40px',
                                                height: '40px',
                                                marginLeft: '10px',
                                                borderRadius: '50%', // Makes the preview circular
                                            }}>
                                                <img src={imagePreview} alt="Attached" style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    cursor: "pointer"
                                                }}
                                                    onClick={() => handleImageClick(imagePreview)}
                                                />
                                                <span onClick={() => { setAttachedImage(null); setImagePreview(null); }}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0',
                                                        right: '0',
                                                        cursor: 'pointer',
                                                        background: 'black',
                                                        borderRadius: '50%',
                                                        width: '14px', // Smaller button size
                                                        height: '14px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)'
                                                    }}>
                                                    <i className="bi bi-x" style={{ color: "white", fontSize: '0.8em' }}></i>
                                                </span>
                                            </div>
                                        )}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }} // Hide the input
                                            ref={fileInputRef} // React ref to trigger click
                                            onChange={handleFileChange} // Function to handle file selection
                                            id="fileInput" // Optional: for label association
                                        />
                                        <button
                                            type="button"
                                            style={{
                                                border: 'none',
                                                background: 'none',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => fileInputRef.current.click()} // Trigger the file input
                                        >
                                            <i className="bi bi-paperclip" style={{ fontSize: '1.5em' }}></i>
                                        </button>
                                        {(playing || IsStreaming) && <span onClick={stopEverything}>
                                            <i class="bi bi-stop-circle" style={{ fontSize: '1.4em', cursor: 'pointer', marginLeft: '5px', color: "red" }}>
                                            </i>
                                        </span>
                                        }
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn"
                                        disabled={reqSent}
                                        style={{
                                            border: 'none',
                                            borderRadius: '20px',
                                            background: 'none',
                                            cursor: 'pointer',
                                            paddingLeft: window.innerWidth <= 425 ? '0' : '12px'
                                        }}
                                    >
                                        <i className="bi bi-send" style={{ fontSize: '1.5em' }}></i>
                                    </button>
                                </form>
                                {/* {ChatTextBoxtoggle ?
                                    <span style={{ cursor: 'pointer' }} onClick={HandleChatTextBoxtoggle}>
                                        <i className="bi bi-x-circle" style={{ fontSize: '1.8em' }}></i>
                                    </span>
                                    : <span style={{ cursor: 'pointer' }} onClick={HandleChatTextBoxtoggle}>
                                        <i className="bi bi-chat-dots" style={{ fontSize: '1.8em' }}></i>
                                    </span>
                                }  */}
                                {showOptions ? <span style={{ marginRight: '2px' }}><select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={displayOption}
                                    onChange={e => handleChatTypechange(e)}
                                >
                                    <option value="ChatAndAvatar">Chat and Avatar</option>
                                    <option value="AvatarOnly">Avatar only</option>
                                    <option value="ChatOnly">Chat only</option>
                                    <option value="ChatAndAudio">Chat and Audio</option>
                                </select>
                                </span> : null}
                                {showOptions ?
                                    <span style={{ cursor: 'pointer' }} onClick={toggleOptionsVisibility}>
                                        <i className="bi bi-x-circle" style={{ fontSize: '1.8em' }}></i>
                                    </span>
                                    : <span style={{ cursor: 'pointer' }} onClick={toggleOptionsVisibility}>
                                        <i className="bi bi-chat-dots" style={{ fontSize: '1.8em' }}></i>
                                    </span>
                                }
                            </div>
                            :
                            // <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            //     <input
                            //         className="form-control me-2"
                            //         type="text"
                            //         value={textInput}
                            //         onChange={(e) => setTextInput(e.target.value)}
                            //         onPaste={handlePaste}
                            //         disabled={reqSent}
                            //         placeholder={isListening ? "Listening..." : "Type your message..."}
                            //         style={{ border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '20px', padding: '10px', marginRight: '10px', marginLeft: '10px' }}
                            //     />
                            //     <input
                            //         type="file"
                            //         accept="image/*"
                            //         style={{ display: 'none' }} // Hide the input
                            //         ref={fileInputRef} // React ref to trigger click
                            //         onChange={handleFileChange} // Function to handle file selection
                            //         id="fileInput" // Optional: for label association
                            //     />

                            //     <button
                            //         style={{
                            //             border: 'none',
                            //             borderRadius: '20px',
                            //             background: 'none',
                            //             cursor: 'pointer',
                            //             // paddingLeft: window.innerWidth <= 425 ? '0' : '12px'
                            //         }}
                            //         onClick={() => fileInputRef.current.click()} // Trigger the file input
                            //     >
                            //         <i class="bi bi-paperclip" style={{ fontSize: '1.5em' }}></i>
                            //     </button>
                            //     <button
                            //         type="submit"
                            //         className="btn"
                            //         disabled={reqSent}
                            //         style={{
                            //             border: 'none',
                            //             borderRadius: '20px',
                            //             background: 'none',
                            //             cursor: 'pointer',
                            //             paddingLeft: window.innerWidth <= 425 ? '0' : '12px'
                            //         }}
                            //     >
                            //         <i className="bi bi-send" style={{ fontSize: '1.5em' }}></i>
                            //     </button>
                            //     {/* {imagePreview && (
                            //         <div style={{ position: 'relative', width: 'auto', margin: '10px' }}>
                            //             <img src={imagePreview} alt="Attached" style={{ maxWidth: '60px', maxHeight: '60px' }} />
                            //             <span onClick={() => { setAttachedImage(null); setImagePreview(null); }}
                            //                 style={{ position: 'absolute', top: '0px', right: '1px', cursor: 'pointer' }}>
                            //                 <i className="bi bi-x-circle"></i>
                            //             </span>
                            //         </div>
                            //     )} */}
                            //     {imagePreview && (
                            //         <div style={{
                            //             position: 'relative', // Ensures the child span can be positioned absolutely
                            //             width: 'auto',
                            //             margin: '10px'
                            //         }}>
                            //             <img src={imagePreview} alt="Attached" 
                            //             style={{
                            //                 maxWidth: '60px',
                            //                 maxHeight: '60px'
                            //             }} 
                            //             />
                            //             <span onClick={() => { setAttachedImage(null); setImagePreview(null); }}
                            //                 style={{
                            //                     position: 'absolute',
                            //                     top: '0', 
                            //                     right: '0',
                            //                     cursor: 'pointer',
                            //                     background: 'black', 
                            //                     borderRadius: '50%',
                            //                     width: '20px', 
                            //                     height: '20px',
                            //                     display: 'flex', // Use flexbox for centering the icon
                            //                     alignItems: 'center', // Center vertically
                            //                     justifyContent: 'center', // Center horizontally
                            //                     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' // Optional: add shadow for better visibility
                            //                 }}>
                            //                 <i class="bi bi-x" style={{color:"white"}}></i>
                            //             </span>
                            //         </div>
                            //     )}



                            // </form>

                            <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: '#FFF', // Background of the entire input box
                                    borderRadius: '20px',
                                    boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                                    padding: '5px 10px',
                                    width: '100%', // Ensure the container takes full width of the form
                                    marginRight: '10px',
                                    marginLeft: '10px'
                                }}>
                                    <input
                                        className=" form-control me-2"
                                        type="text"
                                        value={textInput}
                                        onChange={(e) => setTextInput(e.target.value)}
                                        onPaste={handlePaste}
                                        disabled={reqSent}
                                        placeholder={isListening ? "Listening..." : "Type your message..."}
                                        style={{
                                            border: 'none',
                                            flexGrow: 1, // Allows the text input to fill the space
                                            borderRadius: '20px',
                                            padding: '8px 12px',
                                            outline: 'none', // Already included, but ensure this is effective
                                            boxShadow: 'none' // Adding this to remove any focus-induced shadow
                                        }}
                                    />
                                    {imagePreview && (
                                        <div style={{
                                            position: 'relative',
                                            width: '60px',
                                            height: '60px',
                                            marginLeft: '10px',
                                            borderRadius: '50%', // Makes the preview circular
                                        }}>
                                            <img src={imagePreview} alt="Attached" style={{
                                                width: '100%',
                                                height: '100%',
                                                cursor: "pointer"
                                            }}
                                                onClick={() => handleImageClick(imagePreview)}
                                            />
                                            <span onClick={() => { setAttachedImage(null); setImagePreview(null); }}
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    cursor: 'pointer',
                                                    background: 'black',
                                                    borderRadius: '50%',
                                                    width: '14px', // Smaller button size
                                                    height: '14px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)'
                                                }}>
                                                <i className="bi bi-x" style={{ color: "white", fontSize: '0.8em' }}></i>
                                            </span>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }} // Hide the input
                                        ref={fileInputRef} // React ref to trigger click
                                        onChange={handleFileChange} // Function to handle file selection
                                        id="fileInput" // Optional: for label association
                                    />
                                    <button
                                        type="button"
                                        style={{
                                            border: 'none',
                                            background: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => fileInputRef.current.click()} // Trigger the file input
                                    >
                                        <i className="bi bi-paperclip" style={{ fontSize: '1.5em' }}></i>
                                    </button>
                                    {(playing || IsStreaming) && <span onClick={stopEverything}>
                                        <i class="bi bi-stop-circle" style={{ fontSize: '1.4em', cursor: 'pointer', marginLeft: '8px', color: 'red' }}>
                                        </i>
                                    </span>
                                    }
                                </div>
                                <button
                                    type="submit"
                                    className="btn"
                                    disabled={reqSent}
                                    style={{
                                        border: 'none',
                                        borderRadius: '20px',
                                        background: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <i className="bi bi-send" style={{ fontSize: '1.5em' }}></i>
                                </button>
                            </form>

                        }
                    </div>
                </div>
                : null
            }

            {(!ChatTextBoxtoggle || window.innerWidth > 425) && (
                <div style={{ width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                    {displayOption == 'ChatOnly' ? null : (
                        <div className="col d-flex align-items-center justify-content-start" style={{ width: '100%' }}>
                            {isListening ?
                                <span style={{ cursor: 'pointer', color: "#32B749" }} onClick={toggleListen}>
                                    <i className="bi bi-mic-fill" style={{ fontSize: window.innerWidth <= 425 ? '2em' : '2.8em' }}></i>
                                </span>
                                : <span style={{ cursor: 'pointer', color: "#32B749" }} onClick={toggleListen} >
                                    <i className="bi bi-mic-mute-fill" style={{ fontSize: window.innerWidth <= 425 ? '2em' : '2.8em' }}></i>
                                </span>
                            }
                            <div className="sound-waves ml-2">
                                <div className="wave" style={{ width: '60px', height: '60px', display: 'flex', alignItems: 'centre', }}>
                                    {isListening && (<Lottie animationData={listening} />)}
                                    {reqSent && !playing && (<Lottie animationData={loading} />)}
                                    {/* {playing && (<Lottie animationData={speaking} />)} */}
                                    {playing && (
                                        <div
                                            data-toggle="tooltip"
                                            title="Stop Speaking"
                                            data-placement="top"
                                            onClick={stopEverything}
                                            style={{ position: 'relative', width: '100%', height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <Lottie animationData={speaking} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }} />
                                            {/* <img src={stopSpeakingICON} alt="stop speaking" style={{ position: 'absolute', width: '30%', height: '30%', objectFit: 'contain' }} /> */}
                                            <i class="bi bi-stop-circle" style={{ position: 'absolute', objectFit: 'contain', fontSize: '1.4em' }}></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>)}
                    <div className="col d-flex align-items-center justify-content-end">

                        {showOptions ? <span style={{ marginRight: "5px" }}><select
                            className="form-select"
                            aria-label="Default select example"
                            value={displayOption}
                            onChange={e => handleChatTypechange(e)}
                        >
                            <option value="ChatAndAvatar">Chat and Avatar</option>
                            <option value="AvatarOnly">Avatar only</option>
                            <option value="ChatOnly">Chat only</option>
                            <option value="ChatAndAudio">Chat and Audio</option>
                        </select>
                        </span> : null}

                        {showOptions ?
                            <span style={{ cursor: 'pointer' }} onClick={toggleOptionsVisibility}>
                                <i className="bi bi-x-circle" style={{ fontSize: window.innerWidth <= 425 ? '2em' : '2.5em' }}></i>
                            </span>
                            : <span style={{ cursor: 'pointer' }} onClick={toggleOptionsVisibility}>
                                <i className="bi bi-chat-dots" style={{ fontSize: window.innerWidth <= 425 ? '2em' : '2.5em' }}></i>
                            </span>
                        }

                    </div>
                </div>
            )}

            {/* Modal for image preview */}
            <Modal show={showModal} onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body style={{ overflowY: "auto", maxHeight: "70vh" }}>
                    <img src={modalImage} alt="Preview" style={{ width: '100%', maxHeight: "100%", objectFit: "contain" }} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChatWithMicComponent;