import React, { useEffect, useRef, useState, useMemo, Suspense } from "react";
import dynapt from "../../assests/dynapt1.png";
import { useGLTF, useAnimations, Environment } from "@react-three/drei";
import createAnimation from "../converter";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from 'three';
import blinkData from '../blendDataBlink.json';
import ChatWithMicComponent from "./chat";
import { useAuth } from "../auth/authContext";
import { doSignOut } from "../auth/firebase/auth";
import { useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
const _ = require('lodash');

const ChatTextBox = () => {
    console.log("App render");
    const { currentUser } = useAuth();
    const userEmail = currentUser.email;

    let navigate = useNavigate();
   
    const [clips, setClips] = useState();
    const [ChatTextBoxtoggle, setChatTextBoxtoggle] = useState(true);
    const morphTargets = [];
    const morphTargetsName = [];
    
    const [displayOption, setDisplayOption] = useState('ChatAndAvatar'); // Default value

    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

    const handleLogout = () => {
        doSignOut();
        navigate('/');
    };

    const LogoutConfirmation = ({ show, handleClose, handleLogout }) => (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to log out?</Modal.Body>
            <Modal.Footer>
                <button onClick={handleClose} className="btn btn-secondary">Cancel</button>
                <button onClick={() => { handleLogout(); handleClose(); }} className="btn btn-success">Logout</button>
            </Modal.Footer>
        </Modal>
    );

    


    const [windowSize, setWindowSize]=useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    
    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            if ((windowSize.width <= 425 && newWidth > 425) || (windowSize.width > 425 && newWidth <= 425)) {
                setWindowSize({
                    width: newWidth,
                    height: window.innerHeight
                });
            }
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowSize.width]);

    const speechSynthesis = window.speechSynthesis;
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = 'en-IN';

    const playedRef = useRef(true);
    const setRef = () =>{
        playedRef.current = false;
    }

    const Avatar = (props) => {
        const group = useRef();
        const { nodes, materials, animations, scene } = useGLTF("output.glb");
        const { actions, names } = useAnimations(animations, group);

        const mixer = useMemo(() => new THREE.AnimationMixer(scene));
        const mixer2 = useMemo(() => new THREE.AnimationMixer(scene));

        scene.traverse(node => {

            if (node.morphTargetDictionary) {
                morphTargetsName.push(node.name);
                morphTargets.push(node.morphTargetDictionary);
            }
        })

        const BlinkClips = [];

        for (let i = 0; i < Object.keys(morphTargets).length; i++) {
            BlinkClips.push(createAnimation(blinkData, morphTargets[i], morphTargetsName[i]));
        }

      

        useEffect(() => {
            actions[names[0]].play();
            console.log('playing idle');

            _.each(BlinkClips, clip => {
                let clipAction = mixer2.clipAction(clip);
                clipAction.play();
            })

        },[]);

        useFrame((state, delta) => {
            mixer.update(delta);
            mixer2.update(delta);
        });


        useEffect(() => {
            console.log(clips);
            if(!playedRef.current){
                setTimeout(() => {
                _.each(clips, clip => {
                let clipAction = mixer.clipAction(clip);
                clipAction.setLoop(THREE.LoopOnce);
                clipAction.play();
                });
                },  0);
            }
        }, [clips]);

        mixer.addEventListener('finished', function( e	) {
            // setClips();
            playedRef.current = true;
        });



        return (
            <>
                <Suspense fallback={null}>
                    <group ref={group} {...props} dispose={null}>
                        <group name="Scene">
                            <group name="Armature">
                                <skinnedMesh
                                    name="avaturn_hair_0"
                                    geometry={nodes.avaturn_hair_0.geometry}
                                    material={materials.avaturn_hair_0_material}
                                    skeleton={nodes.avaturn_hair_0.skeleton}
                                />
                                <skinnedMesh
                                    name="avaturn_hair_1"
                                    geometry={nodes.avaturn_hair_1.geometry}
                                    material={materials.avaturn_hair_1_material}
                                    skeleton={nodes.avaturn_hair_1.skeleton}
                                />
                                <skinnedMesh
                                    name="avaturn_look_0"
                                    geometry={nodes.avaturn_look_0.geometry}
                                    material={materials.avaturn_look_0_material}
                                    skeleton={nodes.avaturn_look_0.skeleton}
                                />
                                <skinnedMesh
                                    name="avaturn_shoes_0"
                                    geometry={nodes.avaturn_shoes_0.geometry}
                                    material={materials.avaturn_shoes_0_material}
                                    skeleton={nodes.avaturn_shoes_0.skeleton}
                                />
                                <skinnedMesh
                                    name="Body_Mesh"
                                    geometry={nodes.Body_Mesh.geometry}
                                    material={materials.Body}
                                    skeleton={nodes.Body_Mesh.skeleton}
                                />
                                <skinnedMesh
                                    name="Eye_Mesh"
                                    geometry={nodes.Eye_Mesh.geometry}
                                    material={materials.Eyes}
                                    skeleton={nodes.Eye_Mesh.skeleton}
                                    morphTargetDictionary={nodes.Eye_Mesh.morphTargetDictionary}
                                    morphTargetInfluences={nodes.Eye_Mesh.morphTargetInfluences}
                                />
                                <skinnedMesh
                                    name="EyeAO_Mesh"
                                    geometry={nodes.EyeAO_Mesh.geometry}
                                    material={materials.EyeAO}
                                    skeleton={nodes.EyeAO_Mesh.skeleton}
                                    morphTargetDictionary={nodes.EyeAO_Mesh.morphTargetDictionary}
                                    morphTargetInfluences={nodes.EyeAO_Mesh.morphTargetInfluences}
                                />
                                <skinnedMesh
                                    name="Eyelash_Mesh"
                                    geometry={nodes.Eyelash_Mesh.geometry}
                                    material={materials.Eyelash}
                                    skeleton={nodes.Eyelash_Mesh.skeleton}
                                    morphTargetDictionary={nodes.Eyelash_Mesh.morphTargetDictionary}
                                    morphTargetInfluences={nodes.Eyelash_Mesh.morphTargetInfluences}
                                />
                                <skinnedMesh
                                    name="Head_Mesh"
                                    geometry={nodes.Head_Mesh.geometry}
                                    material={materials.Head}
                                    skeleton={nodes.Head_Mesh.skeleton}
                                    morphTargetDictionary={nodes.Head_Mesh.morphTargetDictionary}
                                    morphTargetInfluences={nodes.Head_Mesh.morphTargetInfluences}
                                />
                                <skinnedMesh
                                    name="Teeth_Mesh"
                                    geometry={nodes.Teeth_Mesh.geometry}
                                    material={materials.Teeth}
                                    skeleton={nodes.Teeth_Mesh.skeleton}
                                    morphTargetDictionary={nodes.Teeth_Mesh.morphTargetDictionary}
                                    morphTargetInfluences={nodes.Teeth_Mesh.morphTargetInfluences}
                                />
                                <skinnedMesh
                                    name="Tongue_Mesh"
                                    geometry={nodes.Tongue_Mesh.geometry}
                                    material={materials.Teeth}
                                    skeleton={nodes.Tongue_Mesh.skeleton}
                                    morphTargetDictionary={nodes.Tongue_Mesh.morphTargetDictionary}
                                    morphTargetInfluences={nodes.Tongue_Mesh.morphTargetInfluences}
                                />
                                <primitive object={nodes.Hips} />
                            </group>
                        </group>
                    </group>
                </Suspense>
            </>
        );
    }

    useGLTF.preload("/output.glb");

    return (
        <div className={`container-fluid ${windowSize.width>425 ? 'p-4' : ''}`}>
            <div className={`row align-items-center justify-content-between mb-2 ${windowSize.width <= 425 ? 'mt-2' : ''}`}>
                    <div className={'col-6 col-sm-1 col-md-3 col-lg-2 text-center'}>
                        <img src={dynapt} width="160" alt="dynapt" className="img-fluid" />
                    </div>
                    {windowSize.width>425 && (
                    <div className={'col-sm-6 col-md-7 col-lg-6 text-center'}>
                        <h2 style={{ color: "#32B749", fontSize: '24px', fontWeight: 'bold' }} >Welcome to Dynapt MAX Support</h2>
                    </div>
                    )}
                    <div className={'col-6 col-sm-1 col-md-2 col-lg-2 text-right'}>
                        <button onClick={() => setShowLogoutConfirm(true)} className='text-sm text-green-600 underline'>Logout</button>
                        <LogoutConfirmation
                            show={showLogoutConfirm}
                            handleClose={() => setShowLogoutConfirm(false)}
                            handleLogout={handleLogout}
                        />
                    </div>

            </div>
            {windowSize.width>425 && (
            <div className="row">
                {displayOption ==='ChatAndAudio' ||displayOption ==='ChatOnly'?
                null
                :<div className={`col-lg-${ChatTextBoxtoggle ? '6' : '12'} col-md-6 col-sm-12`} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Canvas shadows camera={{ position: [0, 0.7, 10], fov: 42 }} dpr={2} onCreated={(ctx) => {
                    ctx.gl.physicallyCorrectLights = true;
                }} style={{width: '100%', height:'100%'}}> 
                <Avatar position={[0, -5, 6]} scale={3.5} />
                <Environment preset="sunset" />
                </Canvas>
                </div>}
                
                <ChatWithMicComponent 
                userEmail={userEmail} 
                clips={clips}
                setClips={setClips} 
                morphTargets={morphTargets} 
                morphTargetsName={morphTargetsName} 
                ChatTextBoxtoggle={ChatTextBoxtoggle}
                setChatTextBoxtoggle={setChatTextBoxtoggle}
                setRef={setRef}
                recognition={recognition}
                speechSynthesis={speechSynthesis}
                displayOption={displayOption}
                setDisplayOption={setDisplayOption}
                />
            </div>)}
            {windowSize.width<=425 && (
            <div className="row">
                {displayOption ==='ChatAndAudio' ||displayOption ==='ChatOnly'?null:
                 <div className={`col-lg-${ChatTextBoxtoggle ? '6' : '12'} col-md-6 col-sm-12`} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: ChatTextBoxtoggle ? '37vh' : '75vh' }}>
                 <Canvas shadows camera={{ position: [0, 0.7, 10], fov: 42 }} dpr={2} onCreated={(ctx) => {
                     ctx.gl.physicallyCorrectLights = true;
                 }} style={{width: '100%', height:'100%', border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.3)', borderRadius: '20px'}}> 
                 {!ChatTextBoxtoggle ?
                 <Avatar position={[0, -5, 6]} scale={3.5} />:
                 <Avatar position={[0, -9.4, 6]} scale={6} />
                 }
                 <Environment preset="sunset" />
                 </Canvas>
                 </div>
                 }
                 <ChatWithMicComponent 
                 userEmail={userEmail} 
                 clips={clips}
                 setClips={setClips} 
                 morphTargets={morphTargets} 
                 morphTargetsName={morphTargetsName} 
                 ChatTextBoxtoggle={ChatTextBoxtoggle}
                 setChatTextBoxtoggle={setChatTextBoxtoggle}
                 setRef={setRef}
                 recognition={recognition}
                 speechSynthesis={speechSynthesis}
                 displayOption={displayOption}
                 setDisplayOption={setDisplayOption}
                 />
            </div>
            )}
        </div>

    )
}

export default ChatTextBox;