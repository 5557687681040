import React, { useEffect,useState } from "react";
import {
  HashRouter  as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MainHeader from "./components/MainHeader";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Modal from "react-modal";
import UploadFile from "./pages/UploadFile";
import Chats from "./pages/Chats";
import SpeechText from "./pages/SpeechText";
import BotAi from "./pages/BotAi";
import ChatBot from "./pages/chatbot_new/avatar";
import Admin from "./pages/Admin";
import CrudFaq from "./components/CrudFaq";
import FileUpload from "./components/FileUpload";
import CusomizeApp from "./components/CusomizeApp";
import HomeLayout from "./components/HomeLayout";
import AccessToken from "./components/AccessToken";
import Avtars from "./components/Avatars";
import TalkingAvtras from "./components/TalkinAvtar";
import ChatingBot from "./pages/ChatingBot";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";
import { loginRequest } from "./auth-config";
import MicrosoftLoginButton from './pages/sso'
import LoginPage from "./pages/chatbot_new/loginPage";
import SignupPage from "./pages/chatbot_new/signupPage";
import PhoneVerificationPage from "./pages/chatbot_new/phoneNumberVerification";
import VerifyEmailPage from "./pages/chatbot_new/verifyEmail";
import { useAuth } from "./pages/auth/authContext";

const App = () => {
  const { userLoggedIn, isEmailVerified, isEmailUser  } = useAuth();


  return (
    <Router>
      <Routes>
        <Route path="/" element={userLoggedIn ? ((isEmailUser)? (isEmailVerified? <ChatBot/>:<VerifyEmailPage/>): (<PhoneVerificationPage />)) : <LoginPage/>} />
        <Route path="/signup" element={<SignupPage />} />
      </Routes>
    </Router>
  );
}

export default App;
